<template>
  <b-card>
    <b-card-title>{{ item.subject }}</b-card-title>
    <b-card-sub-title>
      <b-icon-stopwatch-fill></b-icon-stopwatch-fill>
      {{ dateHeure }}
    </b-card-sub-title>
    <b-card-text>
      <b-icon-person-fill></b-icon-person-fill>
      <b-icon-arrow-right-short></b-icon-arrow-right-short>
      <EmailComponent :value="item.sentBy"></EmailComponent>
      via
      <EmailComponent :value="item.fromAddress"></EmailComponent>
      <div v-if="to.length > 0">
        <b-icon-person-fill></b-icon-person-fill>
        <b-icon-arrow-left-short></b-icon-arrow-left-short>
        <span v-if="to.length === 1">
          <EmailComponent :value="to[0]"></EmailComponent>
        </span>
        <span v-b-toggle="`to_${item.idLog}`" v-if="to.length > 1">
          <span class="when-open">
            <b-icon-chevron-up></b-icon-chevron-up>
          </span>
          <span class="when-closed">
            <b-icon-chevron-down></b-icon-chevron-down>
          </span>
          Multiples
        </span>
        <b-collapse :id="`to_${item.idLog}`">
          <ul>
            <li v-for="(address, index) in to" :key="index">
              <EmailComponent :value="address"></EmailComponent>
            </li>
          </ul>
        </b-collapse>
      </div>
      <div v-if="copyTo.length > 0">
        <b-icon-person-plus-fill></b-icon-person-plus-fill>
        <b-icon-arrow-left-short></b-icon-arrow-left-short>
        <span v-if="copyTo.length === 1">
          <EmailComponent :value="copyTo[0]"></EmailComponent>
        </span>
        <span v-b-toggle="`copyTo_${item.idLog}`" v-if="copyTo.length > 1">
          <span class="when-open">
            <b-icon-chevron-up></b-icon-chevron-up>
          </span>
          <span class="when-closed">
            <b-icon-chevron-down></b-icon-chevron-down>
          </span>
          Multiples
        </span>
        <b-collapse :id="`copyTo_${item.idLog}`">
          <ul>
            <li v-for="(address, index) in copyTo" :key="index">
              <EmailComponent :value="address"></EmailComponent>
            </li>
          </ul>
        </b-collapse>
      </div>
    </b-card-text>
    <b-card-text v-if="item.text">
      <span v-b-toggle="`text_${item.idLog}`">
        <span class="when-open">
          <b-icon-chevron-up></b-icon-chevron-up>
        </span>
        <span class="when-closed">
          <b-icon-chevron-down></b-icon-chevron-down>
        </span>
        Message
      </span>
      <b-collapse :id="`text_${item.idLog}`">
        {{ item.text }}
      </b-collapse>
    </b-card-text>
    <b-card-text v-if="item.errorName">
      <b-icon-x-circle-fill variant="danger"></b-icon-x-circle-fill>
      {{ item.errorName }}
      <br />
      {{ item.comment }}
    </b-card-text>
    <b-row>
      <b-col>
        <b-button pill block variant="outline-primary" @click="$emit('close')">
          Fermer
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import EmailComponent from "../../components/EmailComponent.vue";
export default {
  name: "LogMailComponent",
  props: ["item"],
  components: { EmailComponent },
  computed: {
    to() {
      return this.item.toAddress?.split(";") || [];
    },
    copyTo() {
      return this.item.copyTo?.split(";") || [];
    },
    dateHeure() {
      return new Date(this.item.sentAt).toLocaleString();
    },
  },
};
</script>

<style scoped></style>
