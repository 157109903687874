<template>
  <section>
    <TitleBar tabsParent="backoffice">
      Backoffice
      <template #toolbar-content>
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #cell(sentAt)="{ item }">
          <DateTimeComponent :value="item.sentAt"></DateTimeComponent>
        </template>
        <template #cell(sentBy)="{ item }">
          <EmailComponent :value="item.sentBy"></EmailComponent>
        </template>
        <template #cell(sentTo)="{ item }">
          <EmailComponent
            :value="item.toAddress"
            :text="getSentTo(item)"
          ></EmailComponent>
        </template>
        <template #cell(errorName)="{ item }">
          <b-icon-check-circle-fill v-if="!item.errorName" variant="success">
          </b-icon-check-circle-fill>
          <b-icon-x-circle-fill
            v-if="item.errorName"
            variant="danger"
          ></b-icon-x-circle-fill>
        </template>
        <template #cell(actions)="row">
          <b-button pill size="sm" variant="primary" @click="row.toggleDetails">
            D&eacute;tails
          </b-button>
        </template>
        <template #row-details="row">
          <LogMailComponent
            :item="row.item"
            @close="row.toggleDetails"
          ></LogMailComponent>
        </template>
      </PaginatedTable>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import DateTimeComponent from "../../components/DateTimeComponent.vue";
import EmailComponent from "../../components/EmailComponent.vue";
import LogMailComponent from "../../components/backoffice/LogMailComponent.vue";
import BackofficeService from "../../services/backoffice.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "SuiviMailsView",
  components: {
    TitleBar,
    TableButtonGroup,
    ErrorComponent,
    PaginatedTable,
    DateTimeComponent,
    EmailComponent,
    LogMailComponent,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.MAILS),
      error: null,
      page: null,
    };
  },
  computed: {
    ...tableComputed,
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    async load() {
      this.error = null;
      try {
        this.busy = true;
        let payload = this.getSearchPayload();
        let response = await BackofficeService.getLogsMails(payload);
        this.page = response.data;
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    getSentTo(item) {
      let split = item.toAddress?.split(";") || [];
      return split.length > 1 ? "Multiples" : split[0] || "";
    },
  },
};
</script>
